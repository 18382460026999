import {Component, OnInit} from '@angular/core';
import {environment} from '@env/environment';
import {LibMetaService} from '@bvt-common/library/meta';
import {SiteIdService, ApiService, AnalyticsService} from '@app/services';
import {Advisor} from '@app/models/advisor.model';
import {Subject, takeUntil} from 'rxjs';
import {CommonModule} from '@angular/common';
import {MailIcon, PhoneIcon} from '@app/icons';
import {PhonePipe} from '@app/pipes';

@Component({
  standalone: true,
  selector: 'app-advisors',
  imports: [CommonModule, MailIcon, PhonePipe, PhoneIcon],
  templateUrl: './advisors.component.html',
  styleUrls: ['./advisors.component.css'],
})
export class AdvisorsComponent implements OnInit {
  siteID;
  vendorID;
  placeholderImg = environment.shipDefaultImg;
  viewMore = false;
  viewMoreIndex = 0;

  advisors: Array<Advisor> = [];
  private destroy$ = new Subject<void>();

  constructor(
    private headerService: LibMetaService,
    private siteIdService: SiteIdService,
    private api: ApiService,
    private analyticsService: AnalyticsService
  ) {
    this.siteID = this.siteIdService.site.siteID;
    this.vendorID = this.siteIdService.site.vendorID;
  }

  ngOnInit() {
    this.api
      .get<Advisor[]>(`/advisors?vendor_id=${this.vendorID}&md`)
      .pipe(takeUntil(this.destroy$))
      .subscribe((advisors: Advisor[]) => {
        this.advisors = advisors;
      });

    // title/description meta + pageview analytics
    this.setHeaderTags();
    this.analyticsService.recordPageView();
  }

  viewMoreToggle(index: number) {
    this.viewMoreIndex = index;
    this.viewMore = !this.viewMore;
  }

  private setHeaderTags() {
    const canonicalUrl = `https://${this.siteIdService.site.domain}/advisor-profiles`;
    this.headerService.updateMeta(
      `Advisors | ${this.siteIdService.site.websiteName}`,
      `${this.siteIdService.site.websiteName} - Meet Our Sailing Experts: Your Guides to Extraordinary Experiences`,
      [
        {
          property: 'og:title',
          content: `Advisors | ${this.siteIdService.site.websiteName}`,
        },
        {
          property: 'og:url',
          content: canonicalUrl,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:description',
          content: `${this.siteIdService.site.websiteName} - Meet Our Sailing Experts: Your Guides to Extraordinary Experiences`,
        },
        {
          property: 'og:image',
          content: `https://microsites.imgix.net/${this.siteIdService.site.metaImgDefault.path}?auto=format&fit=crop&h=630&w=1200`,
        },
        {
          property: 'og:image:width',
          content: '1200',
        },
        {
          property: 'og:image:height',
          content: '630',
        },
        {
          property: 'og:image:alt',
          content: `${this.siteIdService.site.metaImgDefault.alt}`,
        },
        {
          property: 'og:site_name',
          content: this.siteIdService.site.domain,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:image:alt',
          content: `${this.siteIdService.site.metaImgDefault.alt}`,
        },
        {
          name: 'apple-mobile-web-app-title',
          content: this.siteIdService.site.domain,
        },
        {
          name: 'application-name',
          content: this.siteIdService.site.domain,
        },
      ]
    );

    // set canonical url
    this.headerService.setCanonicalUrl(canonicalUrl);
  }
}
