<div class="flex lg:bg-primary-300">
  <div class="flex-1">
    <button (click)="toggleMenu($event, 0)" [attr.aria-expanded]="activeMenuIdx === 0" aria-controls="menu"
      [class]="activeMenuIdx === 0 ? 'bg-tertiary-300' : 'bg-tertiary-100'"
      class="lg:hidden w-full h-full flex items-center justify-center py-2 px-5 text-white border-y-2 border-r-2 border-b-tertiary-200 border-r-tertiary-200 border-t-transparent">
      <span class="font-theme-serif uppercase text-sm font-bold">
        <span class="text-white">Search Cruises</span>
      </span>
      <span class="text-white">
        <i [class]="activeMenuIdx === 0 ? 'active' : ''" class="indicator relative -top-px block ml-2">
          <icon-chevron [width]="12"></icon-chevron>
        </i>
      </span>
    </button>
    <div id="menu" [class]="activeMenuIdx === 0 ? 'block' : 'hidden'"
      class="search-bar-cont absolute lg:block lg:static top-full left-0 right-0 width-container p-4 bg-primary-300 overflow-y-auto lg:overflow-y-visible">
      <search-bar-2 *appShellNoRender [closeAll$]="closeAccordionFacets$" (didSearch)="closeMenu()"></search-bar-2>
    </div>
  </div>
</div>