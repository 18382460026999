<div class="px-8 py-20 lg:px-24 xl:px-40 lg:w-2/3 m-auto shadow-2xl styles">
  <h1 class="text-center">Terms and Conditions</h1>
  <div class="text-center mb-12">{{siteID}} Cruise Sale</div>
  <p class="mytext-base leading-6">
    All information, pricing and promotions are subject to change without notice and we reserve the right to correct any and all errors made online, via email or over the phone.
  </p>
  <p>While we do our very best to ensure that information and pricing appearing in this website is complete and accurate, we cannot be responsible for incomplete and inaccurate representations, which may or may not be under our control. In the event of a pricing error, misrepresentation or omission, whether verbal or in writing, we reserve the right to adjust the pricing or make any other corrections in regards to the cruise or related aspects of the vacation. It is the responsibility of users to make their own decisions about the accuracy, reliability and correctness of information contained on these sites.</p><br>
  
  <div>
    <span>Copyright &copy; {{ now | date:'Y' }}, &nbsp;</span>All Rights Reserved, Bon Voyage Travel
  </div>
</div>
