import {Component} from '@angular/core';
import {RouterLink} from '@angular/router';
import {SearchResultsComponent} from './search-results/search-results.component';
import {appShellNoRenderDirective} from '@app/directives';
import {AnalyticsService, SiteIdService} from '@app/services';
import {LibMetaService} from '@bvt-common/library/meta';

@Component({
  standalone: true,
  selector: 'search-landing',
  imports: [RouterLink, SearchResultsComponent, appShellNoRenderDirective],
  templateUrl: './search-landing.component.html',
  styleUrls: ['./search-landing.component.css'],
})
export class SearchLandingComponent {
  constructor(
    private siteIdService: SiteIdService,
    private analyticsService: AnalyticsService,
    private headerService: LibMetaService
  ) {
    // title/description meta + pageview analytics
    this.setHeaderTags();
    this.analyticsService.recordPageView();
  }

  private setHeaderTags() {
    const canonicalUrl = `https://${this.siteIdService.site.domain}/search`;
    this.headerService.updateMeta(
      `Search Cruises | ${this.siteIdService.site.websiteName}`,
      `Search Cruises | ${this.siteIdService.site.websiteName}`,
      [
        {
          property: 'og:title',
          content: `Search Cruises | ${this.siteIdService.site.websiteName}`,
        },
        {
          property: 'og:url',
          content: canonicalUrl,
        },
        {
          property: 'og:description',
          content: `Search Cruises | ${this.siteIdService.site.websiteName}`,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: `https://microsites.imgix.net/${this.siteIdService.site.metaImgDefault.path}?auto=format&fit=crop&h=630&w=1200`,
        },
        {
          property: 'og:image:width',
          content: '1200',
        },
        {
          property: 'og:image:height',
          content: '630',
        },
        {
          property: 'og:image:alt',
          content: `${this.siteIdService.site.metaImgDefault.alt}`,
        },
        {
          property: 'og:site_name',
          content: this.siteIdService.site.domain,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:image:alt',
          content: `${this.siteIdService.site.metaImgDefault.alt}`,
        },
        {
          name: 'apple-mobile-web-app-title',
          content: this.siteIdService.site.domain,
        },
        {
          name: 'application-name',
          content: this.siteIdService.site.domain,
        },
      ]
    );

    // canonical tag
    this.headerService.setCanonicalUrl(canonicalUrl);
  }
}
