import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  OnDestroy,
  makeStateKey,
  TransferState,
} from '@angular/core';
import {isPlatformServer} from '@angular/common';

import {Subject, Observable, of} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {Destination, Microsite} from '@app/models';
import {SiteIdService, ApiService, AnalyticsService} from '@app/services';
import {LibMetaService} from '@bvt-common/library/meta';
import {environment} from '@env/environment';
import {DestinationCardComponent} from './destination-card/destination-card.component';

@Component({
  standalone: true,
  selector: 'app-destinations',
  imports: [DestinationCardComponent],
  templateUrl: './destinations.component.html',
  styleUrls: ['./destinations.component.css'],
})
export class DestinationsComponent implements OnInit, OnDestroy {
  site: Microsite;
  destinations: Array<Destination> = [];
  placeholderImg = environment.shipDefaultImg;

  private destroy$ = new Subject<void>();

  constructor(
    private headerService: LibMetaService,
    private siteIdService: SiteIdService,
    private api: ApiService,
    private analyticsService: AnalyticsService,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.site = this.siteIdService.site;
  }

  ngOnInit() {
    //fetch destinations
    this.fetchDestinations().subscribe(res => {
      this.destinations = res;
    });

    // title/description meta + pageview analytics
    this.setHeaderTags();
    this.analyticsService.recordPageView();
  }

  private setHeaderTags() {
    const canonicalUrl = `https://${this.site.domain}/destinations`;
    this.headerService.updateMeta(
      `Destinations | ${this.site.websiteName}`,
      `Plan your dream cruise to diverse destinations with ${this.site.cruiselineName} through ${this.site.websiteName}. Explore the world's most enticing locales on ${this.siteIdService.site.domain}.`,
      [
        {
          property: 'og:title',
          content: `Destinations | ${this.site.websiteName}`,
        },
        {
          property: 'og:url',
          content: canonicalUrl,
        },
        {
          property: 'og:description',
          content: `Plan your dream cruise to diverse destinations with ${this.site.cruiselineName} through ${this.site.websiteName}. Explore the world's most enticing locales on ${this.siteIdService.site.domain}.`,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: `https://microsites.imgix.net/${this.site.heros.destinations.path}?auto=format&fit=crop&h=630&w=1200`,
        },
        {
          property: 'og:image:width',
          content: '1200',
        },
        {
          property: 'og:image:height',
          content: '630',
        },
        {
          property: 'og:image:alt',
          content: `${this.site.heros.destinations.alt}`,
        },
        {
          property: 'og:site_name',
          content: this.site.domain,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:image:alt',
          content: `${this.site.heros.destinations.alt}`,
        },
        {
          name: 'apple-mobile-web-app-title',
          content: this.site.domain,
        },
        {
          name: 'application-name',
          content: this.site.domain,
        },
      ]
    );

    // canonical tag
    this.headerService.setCanonicalUrl(canonicalUrl);
  }

  private fetchDestinations(): Observable<Destination[]> {
    const storeKey = makeStateKey<Destination[]>('destinations');
    if (this.transferState.hasKey(storeKey)) {
      const destinations = this.transferState.get(storeKey, null);
      this.transferState.remove(storeKey);
      return of(destinations as Destination[]);
    } else {
      return this.api
        .get<Destination[]>(
          `/destinations?site=${this.site.siteID}&cruiseline_id=${this.site.cruiselineID}`
        )
        .pipe(
          takeUntil(this.destroy$),
          tap(destinations => {
            if (isPlatformServer(this.platformId)) {
              this.transferState.set(storeKey, destinations);
            }
          })
        );
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
