import {Component} from '@angular/core';
import {SiteIdService, TripFinderHeaderService} from '@app/services';
import {Site} from '@app/models';
import {NavigationEnd, Router, RouterLink} from '@angular/router';
import {ActionBarComponent} from './action-bar/action-bar.component';
import {NavigationComponent} from './navigation/navigation.component';
import {ImgixPipe} from '@app/pipes';
import {BannerComponent} from './banner/banner.component';
import {CommonModule} from '@angular/common';
import {PhoneIcon} from '../icons/phone.icon';
import {distinctUntilChanged, filter, map, Observable, startWith} from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-header',
  imports: [
    ActionBarComponent,
    NavigationComponent,
    ImgixPipe,
    BannerComponent,
    CommonModule,
    RouterLink,
    PhoneIcon,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
  readonly Site = Site;
  siteID;
  tel;
  mobileMenuOpen = false;
  logoLg = '';
  logoSm: string | undefined;
  showBanner = false;
  showActionBar$: Observable<boolean>;

  constructor(
    private siteIdService: SiteIdService,
    public router: Router,
    public tripFinderHeader: TripFinderHeaderService
  ) {
    this.siteID = this.siteIdService.site.siteID;
    this.logoLg = this.siteIdService.site.logoLg;
    this.logoSm = this.siteIdService.site.logoSm;

    this.tel = this.siteIdService.site.phone.replace(/\D/g, '');

    this.showActionBar$ = this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      startWith(this.router),
      map(route => {
        if (route instanceof Router || route instanceof NavigationEnd) {
          const itinRegex = /^\/itinerary\//g;
          const promoCruiseRegex = /^\/promotions\/.+\/.+\/cruises\//g;
          if (route.url.match(itinRegex) || route.url.match(promoCruiseRegex)) {
            return false;
          }
        }
        return true;
      }),
      distinctUntilChanged()
    );
  }
}
