import {
  Component,
  Inject,
  PLATFORM_ID,
  Renderer2,
  Optional,
  makeStateKey,
  TransferState,
} from '@angular/core';

import {isPlatformBrowser, DOCUMENT} from '@angular/common';
import {Router, RouterOutlet} from '@angular/router';
import {
  SiteIdService,
  ThemeService,
  CaptureMarketingSourcesService,
  ESignupService,
  OverlayService,
} from '@app/services';
import {FooterComponent} from '@app/footer/footer.component';
import {HeaderComponent} from '@app/header/header.component';
import {ActionBarComponent} from '@app/header/action-bar/action-bar.component';

@Component({
  standalone: true,
  selector: 'contained-layout',
  imports: [FooterComponent, RouterOutlet, HeaderComponent, ActionBarComponent],
  templateUrl: './contained-layout.component.html',
})
export class ContainedLayoutComponent {
  siteID;
  constructor(
    public router: Router,
    private themeService: ThemeService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private siteIdService: SiteIdService,
    @Optional() @Inject('CSP_NONCE') private cspNonce: any,
    private transferState: TransferState,
    private captureMarketingSourcesService: CaptureMarketingSourcesService,
    public eSignup: ESignupService,
    public overlayService: OverlayService
  ) {
    this.siteID = this.siteIdService.site.siteID;

    const storeKey = makeStateKey<string>('cspNonce');
    if (this.transferState.hasKey(storeKey)) {
      this.cspNonce = this.transferState.get(storeKey, null);
      this.transferState.remove(storeKey);
    } else {
      this.transferState.set(storeKey, this.cspNonce);
    }

    if (isPlatformBrowser(this.platformId)) {
      // inject GTM script into head
      const containerID = this.siteIdService.site.GTMContainerID;
      this.injectGTMScripts(containerID, this.cspNonce);
      // inject MS Clarity code
      if (this.siteIdService.site?.msClarityID) {
        this.injectmsClarityScripts(
          this.siteIdService.site.msClarityID,
          this.cspNonce
        );
      }
      // inject Meta Pixel code
      if (this.siteIdService.site.FBTrackingPixelID) {
        this.injectFbTrackingCode(
          this.siteIdService.site.FBTrackingPixelID,
          this.cspNonce
        );
      }

      // capture marketing sources
      this.captureMarketingSourcesService.captureMarketingSources();
      // show esignup modal
      this.eSignup.showEsignupModal();
    }
    this.themeService.setTheme(this.siteID);
  }

  overlayClick() {
    this.overlayService.didClick.next(true);
  }

  private injectmsClarityScripts(id: string, nonce: string) {
    const script = this.renderer.createElement('script') as HTMLScriptElement;
    script.type = 'text/javascript';
    const scriptBody = this.renderer.createText(`(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${id}")`);
    this.renderer.setAttribute(script, 'nonce', nonce);
    this.renderer.appendChild(script, scriptBody);
    this.renderer.appendChild(this.document.head, script);
  }

  private injectGTMScripts(containerID: string, nonce: string) {
    const script = this.renderer.createElement('script') as HTMLScriptElement;
    script.type = 'text/javascript';
    const scriptBody = this.renderer
      .createText(`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;var n=d.querySelector('[nonce]');
      n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${containerID}');`);
    this.renderer.setAttribute(script, 'nonce', nonce);
    this.renderer.appendChild(script, scriptBody);
    this.renderer.appendChild(this.document.head, script);
  }

  private injectFbTrackingCode(pixelID: string, nonce: string) {
    const script = this.renderer.createElement('script') as HTMLScriptElement;
    script.type = 'text/javascript';
    const scriptBody = this.renderer.createText(`!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${pixelID}');
      fbq('track', 'PageView');`);
    this.renderer.setAttribute(script, 'nonce', nonce);
    this.renderer.appendChild(script, scriptBody);
    this.renderer.appendChild(this.document.head, script);
  }
}
