import {inject} from '@angular/core';
import {CanMatchFn, Route, Router, UrlSegment} from '@angular/router';
import {Site} from '@app/models';
import {SiteIdService} from '@app/services';

// /ships index page for single vendor microsites (ACS has equivalent ship index pages on: /cruise-lines/slug/vendorID/ships)
export const shipPagesGuard: CanMatchFn = (
  route: Route,
  segments: UrlSegment[]
) => {
  const siteService = inject(SiteIdService);
  const router = inject(Router);

  if (siteService.site.siteID === Site.Alaska) {
    // 404
    return router.parseUrl('/page-not-found');
  }

  return true;
};
