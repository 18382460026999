import {Component, OnInit, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {
  takeUntil,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  combineLatestWith,
  debounceTime,
  withLatestFrom,
  tap,
} from 'rxjs/operators';
import {SearchConfig} from '@app/models/search-new.model';
import {SiteIdService} from '@app/services';
import {ChevronIcon} from '@app/icons/chevron.icon';
import {TruncatePipe} from '@app/pipes/truncate.pipe';
import {AsyncPipe} from '@angular/common';
import {RouterLink, Router, ActivatedRoute} from '@angular/router';
import {configs2} from '../search.config';
import {SearchService} from '@app/services/search.service';
import {PaginatorComponent} from '@app/paginator/paginator.component';
import {PerPageSelectorComponent} from '@app/paginator/per-page-selector/per-page-selector.component';
import {ShowingComponent} from '@app/paginator/showing/showing.component';
import {SortComponent} from '@app/paginator/sort/sort.component';
import {SortMobileComponent} from '@app/paginator/sort-mobile/sort-mobile.component';
import {PaginationState} from '@app/models';
import {SortOption} from '@app/models/search-new.model';
import {ItineraryCardComponent} from '@app/itinerary-card/itinerary-card.component';
import {facetBucketMap} from '../facet-bucket-map';
import {SearchDriver, defaultRequestState} from '@app/services/search-driver';
import {SearchBarService2} from '@app/services/search-bar-service-2.service';

@Component({
  standalone: true,
  selector: 'search-results',
  imports: [
    ChevronIcon,
    TruncatePipe,
    AsyncPipe,
    RouterLink,
    PaginatorComponent,
    PerPageSelectorComponent,
    ShowingComponent,
    SortComponent,
    SortMobileComponent,
    ItineraryCardComponent,
  ],
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.css'],
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  phone: string;
  tel: string;

  searchDriver!: SearchDriver;
  searchConfig!: SearchConfig;
  searchResults$ = new BehaviorSubject<{
    apiResults: 'error' | 'loading' | {total: number; results: any[]};
    limit: number;
    page: number;
    sort: SortOption;
  }>({
    apiResults: 'loading',
    limit: defaultRequestState.limit,
    page: defaultRequestState.page,
    sort: defaultRequestState.sort,
  });
  private allSortedItins$ = new BehaviorSubject<
    | {
        ItineraryID: number;
        Sort: string;
      }[]
    | null
  >(null);
  paginationState$ = new BehaviorSubject<PaginationState>({
    current: 1,
    pagingStart: 0,
    pagingEnd: 0,
    totalResults: 0,
    resultsPerPage: 0,
    totalPages: 0,
  });
  sortState$ = new BehaviorSubject<SortOption>({
    field: 'min_price',
    direction: 'asc',
  });
  sortOptions = [
    {
      label: 'Departure Date - earlier',
      options: {
        field: 'depart_day',
        direction: 'asc' as any,
      },
    },
    {
      label: 'Departure Date - later',
      options: {
        field: 'depart_day',
        direction: 'desc' as any,
      },
    },
    {
      label: 'price - low to high',
      options: {
        field: 'min_price',
        direction: 'asc' as any,
      },
    },
    {
      label: 'price - high to low',
      options: {
        field: 'min_price',
        direction: 'desc' as any,
      },
    },
    {
      label: 'duration - min to max',
      options: {
        field: 'duration',
        direction: 'asc' as any,
      },
    },
    {
      label: 'duration - max to min',
      options: {
        field: 'duration',
        direction: 'desc' as any,
      },
    },
  ];

  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private searchService: SearchService,
    private siteIdService: SiteIdService,
    private searchBarService: SearchBarService2
  ) {
    this.phone = this.siteIdService.site.phone;
    this.tel = this.phone.replace(/\D/g, '');
  }

  ngOnInit(): void {
    this.searchConfig = configs2[this.siteIdService.site.siteID];

    const urlState = this.searchService.transformQueryParamsToRequestState(
      this.searchConfig.facetConfig.facets.flatMap(facet =>
        facet.type === 'destination' ? facet.fields : facet.field
      ),
      this.route.snapshot.queryParamMap
    );

    this.searchDriver = this.searchBarService.searchDriver;

    if (urlState) {
      this.searchDriver.updateRequestState(urlState);
    }

    // pagination state updater
    this.searchResults$
      .pipe(
        filter(
          searchRes =>
            searchRes.apiResults !== 'loading' &&
            searchRes.apiResults !== 'error'
        ),
        map(
          searchRes =>
            searchRes as {
              apiResults: {
                total: number;
                results: any[];
              };
              limit: number;
              page: number;
              sort: SortOption;
            }
        ),
        distinctUntilChanged(
          (prev, curr) =>
            prev.apiResults.total === curr.apiResults.total &&
            prev.limit === curr.limit &&
            prev.page === curr.page
        ),
        map(searchRes =>
          this.searchService.calculatePaginationState(
            searchRes.page,
            searchRes.apiResults.total,
            searchRes.limit
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(paginationState => {
        this.paginationState$.next(paginationState);
      });

    // sort state updater
    this.searchDriver.requestState$
      .pipe(
        distinctUntilChanged(
          (prev, curr) =>
            prev.sort.direction === curr.sort.direction &&
            prev.sort.field === curr.sort.field
        ),
        map(searchRes => searchRes.sort),
        takeUntil(this.destroy$)
      )
      .subscribe(sortState => {
        this.sortState$.next(sortState);
      });

    // update allSortedItins$
    const itinTrigger$ = this.searchDriver.requestState$.pipe(
      distinctUntilChanged(
        (prev, curr) =>
          prev.sort.direction === curr.sort.direction &&
          prev.sort.field === curr.sort.field &&
          this.searchDriver.filterArraysEqual(prev.filters, curr.filters)
      )
    );

    itinTrigger$
      .pipe(
        withLatestFrom(this.searchDriver.requestState$),
        tap(_ => this.allSortedItins$.next(null)),
        switchMap(([_, requestState]) => {
          return this.searchService.fetchAllItins$(
            requestState,
            this.searchDriver.permanentFilters
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(allSortedItins => {
        this.allSortedItins$.next(allSortedItins);
      });

    // update searchResults$
    const paginationTrigger$ = this.searchDriver.requestState$.pipe(
      distinctUntilChanged(
        (prev, curr) => prev.limit === curr.limit && prev.page === curr.page
      )
    );

    const newSortedItins$ = this.allSortedItins$.pipe(
      filter(allSortedItins => allSortedItins !== null),
      map(
        allSortedItins =>
          allSortedItins as {
            ItineraryID: number;
            Sort: string;
          }[]
      )
    );

    paginationTrigger$
      .pipe(
        combineLatestWith(newSortedItins$),
        debounceTime(200),
        withLatestFrom(this.searchDriver.requestState$),
        switchMap(([[_, allSortedItins], requestState]) => {
          return this.searchService
            .fetchSailingData$(
              requestState,
              this.searchDriver.permanentFilters,
              allSortedItins,
              facetBucketMap
            )
            .pipe(
              map(expandedSailingData => {
                return {
                  apiResults: {
                    total: allSortedItins.length,
                    results: expandedSailingData,
                  },
                  limit: requestState.limit,
                  page: requestState.page,
                  sort: requestState.sort,
                };
              })
            );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: searchResults => {
          this.searchResults$.next(searchResults);
        },
        error: error => {
          this.searchResults$.next({
            apiResults: 'error',
            limit: defaultRequestState.limit,
            page: defaultRequestState.page,
            sort: defaultRequestState.sort,
          });
        },
      });

    // sync searchDriver requestState with URL
    this.route.queryParamMap
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        const newRequestState =
          this.searchService.transformQueryParamsToRequestState(
            this.searchDriver.config.facets.flatMap(facet =>
              facet.type === 'destination' ? facet.fields : facet.field
            ),
            params
          );
        if (newRequestState) {
          this.searchDriver.updateRequestState(newRequestState);
        }
      });

    // sync URL with requestState
    this.searchDriver.requestState$
      .pipe(takeUntil(this.destroy$))
      .subscribe(newRequestState => {
        const transformParams =
          this.searchService.transformRequestStateToQueryParams(
            newRequestState,
            this.searchDriver.config.facets.flatMap(facet =>
              facet.type === 'destination' ? facet.fields : facet.field
            )
          );

        this.router.navigate([], {
          queryParams: transformParams,
          queryParamsHandling: 'merge',
        });
      });
  }

  updatePagination(newPage: number) {
    this.searchDriver.updatePage(newPage);
  }

  updatePerPageResults(newResultsPerPage: number) {
    const searchResultsState = this.searchResults$.getValue();
    if (
      searchResultsState.apiResults !== 'loading' &&
      searchResultsState.apiResults !== 'error'
    ) {
      const currentPage = searchResultsState.page;
      const totalResults = searchResultsState.apiResults.total;
      const newPageTotal = Math.ceil(totalResults / newResultsPerPage);
      if (currentPage > newPageTotal) {
        this.searchDriver.updateRequestState({
          page: newPageTotal,
          limit: newResultsPerPage,
        });
      } else {
        this.searchDriver.updateLimit(newResultsPerPage);
      }
    }
  }

  updateSort(newSort: SortOption) {
    this.searchDriver.updateRequestState({page: 1, sort: newSort});
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
