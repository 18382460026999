<section class="theme-{{siteID}} h-96 sm:h-[31.25rem] mb-14 pt-12 lg:mb-30 flex w-full relative">
  @if (hero) {
  <div class="absolute inset-0">
    <picture>
      <source media="(max-width: 640px)" srcset="{{ hero.Photo.Image| imgix:'fit=crop':'h=585':'w=640':'q=10'}}">
      <source media="(max-width: 768px)" srcset="{{ hero.Photo.Image| imgix:'fit=crop':'h=585':'w=768'}}">
      <source media="(max-width: 1024px)" srcset="{{ hero.Photo.Image| imgix:'fit=crop':'h=585':'w=1024'}}">
      <source media="(max-width: 1280px)" srcset="{{ hero.Photo.Image| imgix:'fit=crop':'h=585':'w=1280'}}">
      <img src="{{ hero.Photo.Image| imgix:'fit=crop':'h=585':'w=1920' }}" sizes="(max-width: 1280px) 100vw, 1920px"
        alt="{{ hero.Photo.Alt }}" class="absolute inset-0 w-full h-full object-cover" />
    </picture>
    @if (showVid) {
      <video [autoplay]="true" [muted]="true" [loop]="true" playsinline
        src="https://microsites.imgix.net/{{hero.Video.Video}}"
        class="hidden lg:block absolute inset-0 w-full h-full object-cover"></video>
    }
  </div>
  <div class="absolute overlay inset-0"></div>
  <div class="width-container px-4 absolute sm:relative flex justify-start top-1/2 -translate-y-1/2">
    <div class="cta w-[28rem] text-white">
      <div
        class="flex flex-wrap items-end rounded-t bg-transparent text-xl font-theme-display p-6 tracking-wide border-b-2 border-white">
        <div class="font-theme-display uppercase text-2xl sm:text-4xl pr-2 sm:pr-0">{{hero.TitleEmphasis}}</div>
        <div class="mr-2">{{hero.Title}}</div>
      </div>
      <div class="p-6 rounded-b">
        <p class="opt-out tracking-wide mb-5 leading-relaxed">
          {{hero.Description}}
        </p>
        <div class="flex justify-start">
          @if (hero.IsExternalURL) {
          <a href="{{hero.ButtonURL}}"
            class="btn-grad">{{hero.ButtonText}}</a>
          } @else {
          <button (click)="handleClick(hero.ButtonURL)"
            class="btn-grad">{{hero.ButtonText}}</button>
          }
        </div>
      </div>
    </div>
  </div>
  }
</section>