<div class="width-container lg:px-4">
  <div class="px-4 pt-14 pb-11 text-center">
    <h1 class="opt-out mb-2 font-theme-serif text-lg lg:text-2xl font-semibold">Search Results</h1>
    <div class="text-sm lg:text-base">Experience faraway places that you have always dreamed of.</div>
  </div>
</div>

<div class="width-container lg:px-4 min-h-screen">
  <div class="p-4 lg:mb-10 rounded-sm bg-light-gray shadow-lg">
    <!-- pagination/sort -->
    <div class="relative z-10 flex items-center cls-top-paginator">
      <div class="flex gap-3.5 items-center w-full">
        <app-paginator [paginationState$]="paginationState$"
          (updatePagination)="updatePagination($event)"></app-paginator>
        <div class="hidden lg:block">
          <per-page-selector (updatePerPageResults)="updatePerPageResults($event)" [paginationState$]="paginationState$"></per-page-selector>
        </div>
  
        <app-showing [paginationState$]="paginationState$"></app-showing>
  
        <div class="flex flex-row ml-auto">
          <span class="hidden lg:flex items-center mr-4 font-theme-sans uppercase text-xs font-bold tracking-wider">Sort
            By</span>
          <div class="hidden lg:block">
            <sort [sortState$]="sortState$" [sortOptions]="sortOptions" (updateSort)="updateSort($event)"></sort>
          </div>
          <div class="block lg:hidden">
            <sort-mobile [sortState$]="sortState$" [sortOptions]="sortOptions"
              (updateSort)="updateSort($event)"></sort-mobile>
          </div>
        </div>
      </div>
    </div>
  
    <!-- itinerary cards -->
    @if (searchResults$ | async; as results) {
      @if (results.apiResults === 'loading') {
        <div class="py-14 text-center">
          <p class="font-theme-serif text-lg font-semibold">Loading...</p>
        </div>
      } @else if (results.apiResults === 'error') {
        <div class="py-14 text-center">
          <p class="font-theme-serif text-lg font-semibold">There was an error loading results. Please try again later</p>
        </div>
      } @else if (results.apiResults.results.length > 0) {
        <ol class="opt-out itin-cont px-5 py-8 space-y-8 md:space-y-0 md:grid md:grid-cols-2 md:gap-8 lg:block lg:space-y-8">
          @for (itinerary of results.apiResults.results; track i; let i = $index) {
            <li>
              <itinerary-card [title]="itinerary.title" [subTitle]="itinerary.subTitle" [dateStr]="itinerary.dateStr" [ships]="itinerary.ships" [ports]="itinerary.ports" [pricing]="itinerary.pricing" [shipImg]="itinerary.shipImg" [link]="itinerary.link" [cruiseType]="itinerary.cruiseType" [mapImg]="itinerary.mapImg" [promotions]="itinerary.promotions" [rules]="itinerary.rules" [sigAmenity]="itinerary.sigAmenity"></itinerary-card>
            </li>
          }
        </ol>
      } @else {
        <div class="py-14 text-center">
          <p class="font-theme-serif text-lg font-semibold">Sorry, no results match your search.</p>
          <p class="font-theme-sans">Please contact one of our travel advisors at:
            <a href="tel:{{tel}}">
              <span class="text-xl font-bold link no-underline"><br>{{ phone }}</span>
            </a>
          </p>
        </div>
      }
    } @else {
      <div class="py-14 text-center">
        <p class="font-theme-serif text-lg font-semibold">Sorry, no results match your search.</p>
        <p class="font-theme-sans">Please contact one of our travel advisors at:
          <a href="tel:{{tel}}">
            <span class="text-xl font-bold link no-underline"><br>{{ phone }}</span>
          </a>
        </p>
      </div>
    }
  
    <!-- pagination/sort -->
    <div class="py-4 rounded-sm cls-bottom-paginator">
      <div class="relative z-10 flex items-center">
        <div class="flex gap-3.5 w-full flex-col sm:flex-row items-center">
          <div class="hidden sm:block sm:w-1/5">
            <div class="hidden lg:inline-block">
              <per-page-selector (updatePerPageResults)="updatePerPageResults($event)" [paginationState$]="paginationState$"></per-page-selector>
            </div>
          </div>
          <div class="sm:w-3/5 flex justify-center">
            <app-paginator [showPages]="true" [paginationState$]="paginationState$" (updatePagination)="updatePagination($event)"></app-paginator>
          </div>
          <div class="sm:w-1/5 sm:text-right">
            <app-showing [paginationState$]="paginationState$"></app-showing>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

