<div class="width-container lg:px-4">
  <div class="px-4 pt-14 pb-11 text-center">
    <h1 class="opt-out mb-2 font-theme-serif text-lg lg:text-2xl font-semibold h-7 lg:h-8">{{site.siteID}} Cruise Sale
      Destinations</h1>
    <div class="text-sm lg:text-base h-10 sm:h-7">Uncover Hidden Gems and Iconic Harbors: {{site.siteID}}'s Diverse
      Destinations Await</div>
  </div>
</div>
<div class="px-8 py-12 mb-14 width-container shadow-2xl rounded-b-sm bg-light-gray">
  <div
    class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xxl:grid-cols-4 gap-6 md:gap-8 lg:gap-12 xxxl:gap-48 justify-center place-content-center dest-cont m-auto">
    @for (destination of destinations; track destination.ID) {
    <div class="m-auto w-80 rounded-sm">
      <app-destination-card [destination]="destination"></app-destination-card>
    </div>
    }
  </div>
</div>