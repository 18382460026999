import {AfterViewInit, Component, OnInit} from '@angular/core';
import {LibMetaService} from '@bvt-common/library/meta';
import {
  SiteIdService,
  AnalyticsService,
  HubspotFormService,
} from '@app/services';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css'],
})
export class ContactUsComponent implements OnInit, AfterViewInit {
  siteID;
  contactCopy =
    'Our network of travel professionals is here to help answer your questions and book reservations for your next cruise. Please fill out the form below with your question or comment. If you would prefer to speak over the phone, you can request that we give you a call back.';
  formId = '';
  hsTargetId = 'hsContact';

  constructor(
    private headerService: LibMetaService,
    private siteIdService: SiteIdService,
    private analyticsService: AnalyticsService,
    private hubspotFormService: HubspotFormService
  ) {
    this.siteID = this.siteIdService.site.siteID;
    this.formId = this.siteIdService.site.hubspotFormIDs.contactUs;
  }

  // HUBSPOT FORMS
  // Oceania: https://app.hubspot.com/forms/4256703/editor/c3c862cd-26ea-4377-b114-6eb729295121/edit/form
  // Regent: https://app.hubspot.com/forms/4256703/editor/62105a9f-0d09-49c8-9c55-1c50995ccec2/edit/form?redirectUrl=%2Fforms%2F4256703%3Fquery%3DRCS
  // SilverSea:  https://app.hubspot.com/forms/4256703/editor/a58f9106-eccc-4b7d-b0a2-6c5edcfdaff2/edit/form
  // Windstar: https://app.hubspot.com/forms/4256703/editor/c73c97c1-c94e-4832-ad25-dcf59e98e9aa/edit/form

  ngOnInit() {
    // title/description meta + pageview analytics
    this.setHeaderTags();
    this.analyticsService.recordPageView();
  }

  ngAfterViewInit(): void {
    this.hubspotFormService.addForm(this.hsTargetId, this.formId, {
      event: 'hs_contact_request_submit',
    });
  }

  private setHeaderTags() {
    const canonicalUrl = `https://${this.siteIdService.site.domain}/contact-us`;
    this.headerService.updateMeta(
      `Contact Us | ${this.siteIdService.site.websiteName}`,
      this.contactCopy,
      [
        {
          property: 'og:title',
          content: `Contact Us | ${this.siteIdService.site.websiteName}`,
        },
        {
          property: 'og:url',
          content: canonicalUrl,
        },
        {
          property: 'og:description',
          content: this.contactCopy,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: `https://microsites.imgix.net/${this.siteIdService.site.metaImgDefault.path}?auto=format&fit=crop&h=630&w=1200`,
        },
        {
          property: 'og:image:width',
          content: '1200',
        },
        {
          property: 'og:image:height',
          content: '630',
        },
        {
          property: 'og:image:alt',
          content: `${this.siteIdService.site.metaImgDefault.alt}`,
        },
        {
          property: 'og:site_name',
          content: this.siteIdService.site.domain,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:image:alt',
          content: `${this.siteIdService.site.metaImgDefault.alt}`,
        },
        {
          name: 'apple-mobile-web-app-title',
          content: this.siteIdService.site.domain,
        },
        {
          name: 'application-name',
          content: this.siteIdService.site.domain,
        },
      ]
    );

    // set canonical url
    this.headerService.setCanonicalUrl(canonicalUrl);
  }
}
