<div class="w-full flex flex-grow flex-wrap items-center justify-center mt-36 mb-36">
    <div class="flex flex-wrap justify-center w-full px-8">
        <span class="font-serif leading-none text-6xl text-center w-full mb-4">
            404
        </span>

        <h1 class="text-lg lg:text-2xl leading-tight text-center w-full">
            Page Not Found
        </h1>

        <hr class="chip my-8 w-full">

        <div class="w-full text-center">
            <a href="/contact-us" class="underline">Contact us</a>
        </div>
    </div>
</div>