import {BlockEmbed} from 'quill/blots/block';
import {v4 as uuidv4} from 'uuid';

// eslint-disable-next-line
declare let hbspt: any;

// hubspot form embed blot. Does not support raw HTML Hubspot forms.
export class HubspotFormBlot extends BlockEmbed {
  static override blotName = 'hubspotForm';
  static override tagName = 'div';
  static override className = 'hubspot-form';

  static override create(formId: string) {
    const node = super.create() as HTMLElement;
    node.dataset['hsFormId'] = formId; // hubspot form ID

    const UUID = uuidv4(); // form instance ID; allows rendering same form multiple times on same page
    node.id = `form-instance-${UUID}`;

    // TODO: fill utm marketing params stored in cookie to hubspot
    hbspt.forms.create({
      region: 'na1',
      portalId: '4256703',
      formId: formId,
      target: `#form-instance-${UUID}`,
    });
    return node;
  }

  static override value(domNode: HTMLElement) {
    return domNode.dataset['hsFormId'];
  }
}
