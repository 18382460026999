import * as i0 from '@angular/core';
import { EventEmitter, Directive, Input, Output, NgModule } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
var IntersectionStatus;
(function (IntersectionStatus) {
  IntersectionStatus["Visible"] = "Visible";
  IntersectionStatus["Pending"] = "Pending";
  IntersectionStatus["NotVisible"] = "NotVisible";
})(IntersectionStatus || (IntersectionStatus = {}));
const fromIntersectionObserver = (element, config, debounce, stopObserveAfterVisible) => new Observable(subscriber => {
  const debounceSubject$ = new Subject();
  const intersectionObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      debounceSubject$.next({
        entry,
        observer
      });
    });
  }, config);
  // emit pending
  debounceSubject$.subscribe(() => {
    subscriber.next(IntersectionStatus.Pending);
  });
  // emit visible/invisible
  debounceSubject$.pipe(debounceTime(debounce), switchMap(({
    entry,
    observer
  }) => {
    return isVisible({
      entry,
      observer
    }, config);
  })).subscribe(({
    visible,
    entry,
    observer
  }) => {
    if (visible) {
      subscriber.next(IntersectionStatus.Visible);
      if (stopObserveAfterVisible) {
        observer.unobserve(entry.target);
      }
    } else {
      subscriber.next(IntersectionStatus.NotVisible);
    }
  });
  // start observing
  intersectionObserver.observe(element);
  // teardown
  return {
    unsubscribe() {
      intersectionObserver.disconnect();
      debounceSubject$.unsubscribe();
    }
  };
});
const isIntersecting = entry => {
  return entry.isIntersecting || entry.intersectionRatio > 0;
};
const isVisible = async ({
  entry,
  observer
}, config) => {
  return new Promise(resolve => {
    const tempObserver = new IntersectionObserver(([tempEntry]) => {
      resolve({
        visible: tempEntry.isIntersecting,
        entry: entry,
        observer: observer
      });
      tempObserver.disconnect();
    }, config);
    tempObserver.observe(entry.target);
  });
};
class IntersectionObserverDirective {
  element;
  intersectionDebounce = 0;
  intersectionRootMargin = '0px';
  intersectionRoot;
  intersectionThreshold = 0;
  stopObserveAfterVisible = true;
  visibilityChange = new EventEmitter();
  destroy$ = new Subject();
  constructor(element) {
    this.element = element;
  }
  ngOnInit() {
    const element = this.element.nativeElement;
    const config = {
      root: this.intersectionRoot,
      rootMargin: this.intersectionRootMargin,
      threshold: this.intersectionThreshold
    };
    fromIntersectionObserver(element, config, this.intersectionDebounce, this.stopObserveAfterVisible).pipe(takeUntil(this.destroy$)).subscribe(status => {
      this.visibilityChange.emit(status);
    });
  }
  ngOnDestroy() {
    this.destroy$.next();
  }
  static ɵfac = function IntersectionObserverDirective_Factory(t) {
    return new (t || IntersectionObserverDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: IntersectionObserverDirective,
    selectors: [["", "LibIntersectionObserver", ""]],
    inputs: {
      intersectionDebounce: "intersectionDebounce",
      intersectionRootMargin: "intersectionRootMargin",
      intersectionRoot: "intersectionRoot",
      intersectionThreshold: "intersectionThreshold",
      stopObserveAfterVisible: "stopObserveAfterVisible"
    },
    outputs: {
      visibilityChange: "visibilityChange"
    }
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IntersectionObserverDirective, [{
    type: Directive,
    args: [{
      selector: '[LibIntersectionObserver]'
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    intersectionDebounce: [{
      type: Input
    }],
    intersectionRootMargin: [{
      type: Input
    }],
    intersectionRoot: [{
      type: Input
    }],
    intersectionThreshold: [{
      type: Input
    }],
    stopObserveAfterVisible: [{
      type: Input
    }],
    visibilityChange: [{
      type: Output
    }]
  });
})();
class IntersectionObserverModule {
  static ɵfac = function IntersectionObserverModule_Factory(t) {
    return new (t || IntersectionObserverModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: IntersectionObserverModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IntersectionObserverModule, [{
    type: NgModule,
    args: [{
      declarations: [IntersectionObserverDirective],
      imports: [CommonModule],
      exports: [IntersectionObserverDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of library
 */

/**
 * Generated bundle index. Do not edit.
 */

export { IntersectionObserverDirective, IntersectionObserverModule, IntersectionStatus, fromIntersectionObserver };
