import {QuillConfig} from 'ngx-quill';
import {HubspotFormBlot} from './hubspot-form-blot';

// default toolbar with all buttons
// const toolbarOptions = [
//   ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
//   ['blockquote', 'code-block'],
//   ['link', 'image', 'video', 'formula'],

//   [{ 'header': 1 }, { 'header': 2 }],               // custom button values
//   [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
//   [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
//   [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
//   [{ 'direction': 'rtl' }],                         // text direction

//   [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
//   [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

//   [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
//   [{ 'font': [] }],
//   [{ 'align': [] }],

//   ['clean']                                         // remove formatting button
// ];

export const quillConfig: QuillConfig = {
  format: 'json',
  modules: {
    toolbar: {
      container: [
        // Note false, not 'normal', is the correct value
        // quill.format('font', false) removes the format,
        // allowing default styling to work
        [{font: [false, 'serif', 'display']} as any], // custom microsite fonts
        [{header: [1, 2, 3, 4, 5, 6, false]}],
        [
          {
            size: [
              'extra-small',
              'small',
              false,
              'large',
              'extra-large',
              '2xl',
              '3xl',
            ],
          },
        ], // custom dropdown

        ['bold', 'italic', 'underline', 'strike'], // toggled buttons

        [{list: 'ordered'}, {list: 'bullet'}, {list: 'check'}],
        [{align: []}, {indent: '-1'}, {indent: '+1'}], // outdent/indent

        ['hubspot-form', 'link', 'image', 'video'],

        ['blockquote', 'code-block'],

        [{header: 1}, {header: 2}], // custom button values
        [{script: 'sub'}, {script: 'super'}], // superscript/subscript

        [{direction: 'rtl'}], // text direction

        [{color: []}, {background: []}], // dropdown with defaults from theme

        ['clean'],
      ],
      handlers: {
        'hubspot-form': function (value: any) {
          if (value) {
            const promptId = prompt(
              'Please enter Hubspot form ID.\nExample: 0b7a9272-c43b-4760-9779-5b8667ea9433\nNote: Does not support raw HTML Hubspot forms.'
            );
            const hsFormId = promptId ? promptId.trim() : null;

            if (hsFormId) {
              // check for GUID format
              if (
                !hsFormId.match(
                  /^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/
                )
              ) {
                alert('Invalid hubspot form ID');
                return;
              }

              const range = this['quill'].getSelection(true);
              this['quill'].insertText(range.index, '\n', 'user');
              this['quill'].insertEmbed(
                range.index + 1,
                'hubspotForm',
                hsFormId,
                'user'
              );
              this['quill'].setSelection(range.index + 2, 'silent');
            }
          }
        },
      },
    },
  },
  customOptions: [
    {
      import: 'formats/font',
      whitelist: ['serif', 'display'], // We do not add sans-serif since it is the default see https://quilljs.com/playground/custom-formats
    },
    {
      import: 'formats/size',
      whitelist: ['extra-small', 'small', 'large', 'extra-large', '2xl', '3xl'],
    },
  ],
  customModules: [
    {
      path: 'formats/hubspot-form',
      implementation: HubspotFormBlot,
    },
  ],
};
