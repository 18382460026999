<div class="w-full bg-primary-100 flex flex-col text-white">
  <div class="w-5/6 lg:w-2/3 m-auto flex flex-col justify-center">
    <div class="flex flex-col sm:flex-row-reverse justify-around mx-0 xl:mx-20 p-10">
      <div class="my-4 sm:my-0 sm:border-l sm:pl-6 border-l-gray-500">
        <img [width]="site.footer.formLogo.width" [height]="site.footer.formLogo.height"
          src="{{ site.footer.formLogo.path | imgix:'fit=crop':'height=80'}}"
          alt="{{site.footer.formLogo.alt}}" class="md:mx-auto mb-8">
        <h3 class="sm:text-center opt-out uppercase">Email Sign-Up</h3>
        <div [id]="hsTargetId" class="text-black h-10"></div>
      </div>
      <div class="my-4 sm:my-0 sm:border-l sm:pl-6 border-l-gray-500">
        <h4 class="opt-out uppercase">Plan a Cruise</h4>
        <ul class="opt-out leading-7">
          @switch (site.siteID) {
            @case (Site.Alaska) {
              <li><a class="opt-out" routerLink="/cruise-lines">Cruise Lines</a></li>
              <li><a class="opt-out" routerLink="/promotions">Promotions</a></li>
              <li><a class="opt-out" href="https://www.shoreexcursionsgroup.com/?id=1006221" target="_blank" rel="noopener noreferrer">Shore Excursions</a></li>
            }
            @case (Site.Silversea) {
              <li><a class="opt-out" routerLink="/about-cruiseline">About {{site.siteID}}</a></li>
              <li><a class="opt-out" routerLink="/ships">Ships</a></li>
              <li><a class="opt-out" routerLink="/destinations">Destinations</a></li>
              <li><a class="opt-out" routerLink="/promotions">Promotions</a></li>
              <li><a class="opt-out" routerLink="/frequently-asked-questions">FAQs</a></li>
            }
            @default {
              <li><a class="opt-out" routerLink="/frequently-asked-questions">FAQs</a></li>
              <li><a class="opt-out" routerLink="/ships">Ships</a></li>
              <li><a class="opt-out" routerLink="/destinations">Destinations</a></li>
              <li><a class="opt-out" routerLink="/promotions">Promotions</a></li>
            }
          }
        </ul>
      </div>
      <div class="my-4 sm:my-0">
        <h4 class="opt-out uppercase">Company Info</h4>
        <ul class="opt-out  leading-7">
          <li><a class="opt-out" routerLink="/privacy-policy">Privacy Policy</a></li>
          <li><a class="opt-out" routerLink="/terms-and-conditions">Terms and Conditions</a></li>
          <li><a class="opt-out" routerLink="/why-choose-us">Why Choose Us</a></li>
          <li><a class="opt-out" routerLink="/contact-us">Contact Us</a></li>
        </ul>
      </div>
    </div>
    <div class="logos mb-6 ">
      <ul class="opt-out list-none flex justify-center md:gap-x-14 flex-wrap">
        @for (icon of site.footer.icons; track icon.path) {
        <li class="flex justify-between` m-4 md:mx-0">
          <img [width]="icon.width" height="48" class="max-h-12 max-w-[190px]"
            src="{{ icon.path | imgix:'fit=crop':'height=80'}}" alt="{{ icon.alt }}">
        </li>
        }
      </ul>
    </div>
  </div>
  <div class="sub bg-primary-200 py-4 px-8 text-gray-400 font-thin text-sm flex-col text-center">
    <div class="flex flex-col sm:inline-block md:flex md:flex-row justify-center">

      <div class="flex flex-row justify-center sm:justify-start">
        <span>Copyright &copy; {{ now | date:'Y' }} &nbsp;</span>
        <a [routerLink]="['/']" class="group cursor-pointer">
          <span class="capitalize text-sm text-gray-400 group-hover:text-white underline font-thin mr-1">
            {{site.siteID}} Cruise Sale</span>
        </a>
        <span> - A Division of</span>
      </div>

      <div>
        <span>
          <a href="https://cruiseinsider.com" target="_blank" rel="noreferrer">
            <span
              class="text-gray-400 font-thin hover:text-white cursor-pointer text-sm underline ml-1">Cruiseinsider</span>
          </a> and Subsidiary of
          <a href="https://bvtravel.com" target="_blank" rel="noreferrer">
            <span class="text-gray-400 font-thin hover:text-white text-sm cursor-pointer underline">BonVoyage
              Travel</span>
          </a>
        </span>
      </div>
    </div>
    <div class="text-gray-400 flex justify-center">CA Seller License: 2132310-70</div>
  </div>
</div>