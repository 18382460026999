import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {NavigationService} from '@app/services';

@Component({
  standalone: true,
  selector: 'app-root',
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  // setup navigationservice to start tracking nav changes
  constructor(private navigationService: NavigationService) {}
}
