import {inject} from '@angular/core';
import {CanMatchFn, Route, Router, UrlSegment} from '@angular/router';
import {Site} from '@app/models';
import {SiteIdService} from '@app/services';

// cruiselines page for ACS
export const cruiseLinesGuard: CanMatchFn = (
  route: Route,
  segments: UrlSegment[]
) => {
  const siteService = inject(SiteIdService);
  const router = inject(Router);

  if (siteService.site.siteID === Site.Alaska) {
    return true;
  }

  // 404
  return router.parseUrl('/page-not-found');
};
