<div class="theme-{{siteID}} width-container py-14 lg:px-4 bg-off-white shadow-xl">
  @if (advisors.length > 0) {
  <div class="px-4 pb-11 text-center">
    <h1 class="opt-out mb-2 font-theme-serif text-lg lg:text-2xl font-semibold">Meet Our Cruise Experts</h1>
    <div class="text-sm lg:text-base">Your Compass to Unforgettable Cruising Experiences</div>
  </div>

  <!-- advisor cards -->
  <ol class="opt-out flex flex-row gap-x-8 gap-y-10 justify-center flex-wrap">
    @for (advisor of advisors; track advisor.ID; let i = $index) {
    <li>
      <div [ngClass]="viewMore && viewMoreIndex === i ? 'h-full' : 'h-[36rem]'"
        class="card flex flex-col text-white w-80 mx-auto shadow">
        <div class="info flex flex-row p-6 items-start gap-4 rounded-t">
          <img width="64" height="64"
            src="https://bvtravel.imgix.net/advisors/{{advisor.Photo}}?crop=faces&facepad=1&fit=crop&h=80&w=80"
            alt="{{advisor.First}}'s profile picture" class="w-16 rounded-full">
          <div class="flex flex-col gap-1">
            <div class="font-theme-serif">{{advisor.First}} {{advisor.Last}}</div>
            <div>
              <icon-phone class="mr-2"></icon-phone> <a class="opt-out text-sm font-bold text-white"
                href="tel:+{{advisor.Phone}}">+1 {{advisor.Phone | phone}}</a>
            </div>
            <div>
              <icon-mail class="mr-2"></icon-mail> <a class="opt-out text-white text-sm font-bold"
                href="mailto:{{advisor.Email}}">{{advisor.Email}}</a>
            </div>
          </div>
        </div>
        <div class="bio h-full p-6 flex flex-col gap-3 text-sm leading-7 rounded-b">
          <div [ngClass]="viewMore && viewMoreIndex === i ? 'h-full' : 'h-28'" class="flex-grow overflow-hidden">
            <strong>Bio: </strong><span [innerHTML]="advisor.Bio"></span>
          </div>
          @if (!viewMore || viewMoreIndex !== i) {
          <div class="-mt-5">
            <span (click)="viewMoreToggle(i)" class="font-extrabold cursor-pointer">...Read more</span>
          </div>
          } @else {
          <div>
            <span (click)="viewMoreToggle(i)" class="font-extrabold cursor-pointer">Read less</span>
          </div>
          }
          <div class="mt-auto">
            <strong>Favorite Quote:</strong>
            <div>
              "{{advisor.Quote}}"
            </div>
          </div>
        </div>
      </div>
    </li>
    }
  </ol>
  } @else {
  <div class="py-14 text-center">
    <h1 class="opt-out mb-4 font-theme-serif text-lg lg:text-2xl font-semibold">Sorry, we are unable to find any
      advisors at this time.</h1>
    <p class="font-theme-sans">Please contact one of our travel advisors at:
      <a href="tel:800-439-7963">
        <span class="text-xl font-bold link no-underline"><br>+1 (800) 439-7963</span>
      </a>
    </p>
  </div>
  }
</div>