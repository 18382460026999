import {Component, OnInit, Inject, OnDestroy} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {
  IntersectionObserverModule,
  IntersectionStatus,
} from '@bvt-common/library/intersection-observer';
import {LibMetaService} from '@bvt-common/library/meta';
import {
  TripFinderHeaderService,
  SiteIdService,
  ApiService,
  AnalyticsService,
} from '@app/services';
import {Hero, Promotion, Site} from '@app/models';
import {Subject, takeUntil, of, tap, Observable} from 'rxjs';
import {GoogleReviewsComponent} from '@app/google-reviews/google-reviews.component';
import {YoutubePlayerComponent} from '@app/youtube-player/youtube-player.component';
import {DestinationSliderComponent} from './destination-slider/destination-slider.component';
import {TresCardsComponent} from '@app/tres-cards/tres-cards.component';
import {ImgixPipe} from '@app/pipes';
import {PromotionSliderComponent} from './promotion-slider/promotion-slider.component';
import {HeroComponent} from '@app/hero/hero.component';
import {JsonLDComponent} from '@app/json-ld/json-ld.component';
import {appShellNoRenderDirective} from '@directive/app-shell-no-render.directive';
import {RouterLink} from '@angular/router';
import {SearchBar2Component} from '@app/search-bar-2/search-bar.component';

@Component({
  standalone: true,
  selector: 'home',
  imports: [
    GoogleReviewsComponent,
    YoutubePlayerComponent,
    DestinationSliderComponent,
    TresCardsComponent,
    ImgixPipe,
    PromotionSliderComponent,
    IntersectionObserverModule,
    HeroComponent,
    JsonLDComponent,
    appShellNoRenderDirective,
    RouterLink,
    SearchBar2Component,
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, OnDestroy {
  readonly Site = Site;
  site;
  hero: Hero | null = null;
  showVid = false;
  intersectionDebounce = 500;
  intersectionRootMargin = '0px 0px';
  structuredData: {[key: string]: string}[] = [];
  private destroy$ = new Subject<void>();
  hasPromotions = true;

  constructor(
    private tripFinderHeader: TripFinderHeaderService,
    private headerService: LibMetaService,
    private siteIdService: SiteIdService,
    private api: ApiService,
    private analyticsService: AnalyticsService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.site = this.siteIdService.site;

    //check for promotions before loading slider
    this.api
      .get<Promotion[]>(
        `/promotions?site=${this.site.siteID}&featured&sailings`
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: res => {
          if (!res.length) {
            this.hasPromotions = false;
          }
        },
      });
  }

  ngOnInit(): void {
    this.analyticsService.recordPageView();

    this.api
      .get<Hero>(`/heros/${this.site.siteID}`)
      .pipe(takeUntil(this.destroy$))
      .subscribe(hero => {
        this.hero = new Hero(hero);
        if (
          this.document.defaultView?.innerWidth &&
          this.document.defaultView?.innerWidth > 1024 &&
          this.hero?.VideoID
        ) {
          this.showVid = true;
        }

        const canonicalUrl = `https://${this.siteIdService.site.domain}`;
        this.setHeaderTags(hero.Photo.Image, canonicalUrl);

        // structured data
        this.structuredData = this.getStructuredData(
          hero.Photo.Image,
          canonicalUrl
        );
      });
  }

  onVisibilityChanged(status: IntersectionStatus) {
    switch (status) {
      case IntersectionStatus.Visible:
        this.tripFinderHeader.hide();
        break;
      case IntersectionStatus.NotVisible:
        this.tripFinderHeader.show();
        break;
    }
  }

  private setHeaderTags(image: string, canonicalUrl: string) {
    this.headerService.updateMeta(
      `${this.siteIdService.site.websiteName}`,
      `Explore the world of ${this.siteIdService.site.cruiselineName} with ${this.siteIdService.site.websiteName}. Find exclusive deals, expert guidance, and unforgettable voyages`,
      [
        {
          property: 'og:title',
          content: `${this.siteIdService.site.websiteName}`,
        },
        {
          property: 'og:url',
          content: canonicalUrl,
        },
        {
          property: 'og:description',
          content: `Explore the world of ${this.siteIdService.site.cruiselineName} with ${this.siteIdService.site.websiteName}. Find exclusive deals, expert guidance, and unforgettable voyages`,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: `https://microsites.imgix.net/${image}?auto=format&fit=crop&h=630&w=1200`,
        },
        {
          property: 'og:image:width',
          content: '1200',
        },
        {
          property: 'og:image:height',
          content: '630',
        },
        {
          property: 'og:image:alt',
          content: `${this.site.siteID} Logo`,
        },
        {
          property: 'og:site_name',
          content: this.siteIdService.site.domain,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:image:alt',
          content: `${this.site.siteID} Logo`,
        },
        {
          name: 'apple-mobile-web-app-title',
          content: this.siteIdService.site.domain,
        },
        {
          name: 'application-name',
          content: this.siteIdService.site.domain,
        },
      ]
    );

    // canonical tag
    this.headerService.setCanonicalUrl(canonicalUrl);
  }

  private getStructuredData(image: string, canonicalUrl: string) {
    return [
      {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        url: canonicalUrl,
        logo: `https://microsites.imgix.net/${this.siteIdService.site.logoColor}?auto=format&fit=crop&h=112`,
      },
    ];
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
